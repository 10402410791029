type CDXPSession = {
  sessionId: string;
  sessionType: 'a' | 'i' | 'p';
  status: string;
  valid: boolean;
};

export const CDXPSessionTypes = ['a', 'i', 'p', 'l'] as [
  auth: 'a',
  impersonate: 'i',
  unauth: 'p',
  lite: 'l'
];

type CDXPSessionOpts = (typeof CDXPSessionTypes)[number];

export type { CDXPSession, CDXPSessionOpts };
