import Logger from 'src/lib/Utils/Logger';

const getCDXPSession = () => {
  // check to see if a cdxp-session cookie already exists
  const cdxpCookie = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith('cdxp-session='));
  // if a cdxp-session cookie exists, check to see if the session is valid
  // if the cookie does not exist send the string 'no-session' as the 'Cdxp-Session' header
  // /cdxp/api/core/session/check endpoint requires *something* be entered into the 'Cdxp-Session' header or it returns a 401 error
  const cdxpSessionId = cdxpCookie ? cdxpCookie.split('session=')[1] : 'no-session';

  return fetch('/cdxp/api/core/session/check', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cdxp-Session': cdxpSessionId,
      Authorization: 'Bearer authorized',
    },
  })
    .then(r => r.json())
    .catch(error => {
      Logger(error, { message: 'Error getting session' });
    });
};

export default getCDXPSession;
